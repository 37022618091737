<template>
	<div class="home">
        <div id="content" class="home-content blue-box curved home-page" style="--accent: white">
			<svg width="500" height="200" viewBox="0 0 500 201" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M0,200 L0,26 Q250,-16 500,26 L500,200 Q250,160 0,200Z" fill="#00a7e5"/>

				<clipPath id="sweepClip">
					<path d="M0,200 L0,26 Q250,-16 500,26 L500,200 Q250,160 0,200Z"/>
				</clipPath>
				<clipPath id="halfClip" clip-path="url(#sweepClip)">
					<rect x="250" y="0" width="250" height="200"/>
				</clipPath>

				<pattern id="img" patternUnits="userSpaceOnUse" width="250" height="200" x="0" y="0">
                    <rect x="0" y="0" width="100%" height="100%" fill="white"></rect>
					<image href="@/assets/images/smart-min.svg" x="20" y="0" width="170" height="190" preserveAspectRatio="xMinYMin meet"/>
				</pattern>

				<rect id="imgFill" x="250" y="0" width="250" height="200" fill="url(#img)" clip-path="url(#halfClip)"/>

				<path d="M0,28 L0,24 Q250,-15 500,24 L500,28 Q250,-12 0,28Z" fill="#8fd7f2"/>
				<path d="M0,201 L0,197 Q250,158 500,197 L500,201 Q250,161 0,201Z" fill="#8fd7f2"/>

				<text class="svg-title" x="125" y="90" fill="white" text-anchor="middle">Encore</text>
				<text class="svg-title" x="125" y="110" fill="white" text-anchor="middle">SMART Teaching</text>
			</svg>
		</div>

		<div class="smart-spacer"></div>

        <div id="content" class="home-content">
            <div class="content-wide">
                <div class="smart-nav-row">
                    <div class="smart-nav-button" @click="$router.push('/smart/0')">Encore SMART Teaching</div>
                    <div class="smart-nav-button" @click="$router.push('/smart/1')">The Encore Difference</div>
                    <div class="smart-nav-button" @click="$router.push('/smart/2')">Encore &amp; Exams</div>
                </div>
            </div>
        </div>

        <div id="content" class="home-content">
			<div class="content-wide" v-if="pageIndex == 0">
                <!-- <p class="info-bold">Encore SMART Teaching ensures that students develop musical, technical and creative skills to a high level. This allows students to play from a score, play by ear, write what they hear, transpose pieces and improvise.</p> -->

                <p class="info-bold">Encore SMART teaching allows students to play from a score, play by ear, write what they hear, transpose pieces and improvise.</p>

                <br>

                <div class="smart-row">
                    <img src="@/assets/images/s.svg">
                    <p class="heading-3">Snap Shot</p>
                </div>

                <p class="info">
                    Presenting students with a Snap Shot of a piece allows them to connect immediately with the music. They need to know what the piece is about and what it sounds like. The Snap Shot is also a process of setting clearly-defined goals.
                </p>

                <div class="smart-row">
                    <img src="@/assets/images/m.svg">
                    <p class="heading-3">Movement, Modelling and Gesture</p>
                </div>

                <p class="info">
                    Virtually all musical elements can be experienced through movement and gesture. This kinaesthetic approach is an important part of the learning process. Modelling encompasses a number of teaching approaches, including finger preparation drills, playing tuned and untuned percussion, and using a variety of props. The range of props includes Rainbow Rings, Parachutes and Streamers in Kinder Beat<span class="reg">&reg;</span> through to Keyboard Mats and Music Staff Mats in <span class="info-italic">Kinder Beat Piano Prep</span>, <span class="info-italic">Encore on Keys</span> and <span class="info-italic">Encore on Strings</span>.
                </p>


                <div class="smart-row">
                    <img src="@/assets/images/a.svg">
                    <p class="heading-3">Aural and Singing</p>
                </div>
                
                <p class="info">
                    Music is essentially an aural art form. Information acquired aurally is confirmed and reinforced through singing and verbalisation. Students develop an internal library of musical sounds and patterns through solfa/solfege singing. This is reinforced on their instrument through the By Ear Playing and Singing (BEPS) of key melodic and harmonic patterns.
                </p>

                <div class="smart-row">
                    <img src="@/assets/images/r.svg">
                    <p class="heading-3">Rhythm and Reading</p>
                </div>

                <p class="info">
                    Rhythm is fundamental to all music-making. It is the framework that ensures a strong connection between the other musical elements. The Encore approach to rhythm includes body percussion, movement, gesture, speech or word patterns and time names. This multi sensory approach is how beat, pulse, whole rhythmic units and phrasing are introduced, reinforced and internalised. Reading skills are developed through the Encore Flash Card System. The Flash Card packs are specific to each level of the Kinder Beat, Junior Piano and Primary Piano courses. Students not only learn individual notes, pitches and values, but more importantly, they develop an understanding of the relationships that underpin the larger musical units such as bars/measures and phrases.
                </p>

                <div class="smart-row">
                    <img src="@/assets/images/t.svg">
                    <p class="heading-3">Technique and Artistry</p>
                </div>

                <p class="info">
                    Instrumental playing requires a robust and reliable technique. Without adequate technical skills, musical communication is limited. The Encore approach systematically embeds technical fundamentals into each course. These skills are nurtured and refined as students progress through each level of the Encore SMART Teaching. Artistic creativity and communication is the ultimate goal of the Encore SMART Teaching. This is developed through exposure to a range of authentic styles and extended to include performance and improvisation skills.
                </p>
			</div>
            

            <div class="content-wide" v-if="pageIndex == 1">
				<p class="heading handwriting left" style="margin-bottom: 0px">The Encore Difference</p>

                <p class="info">Encore is a truly unique approach to music education. The innovative teaching strategies and learning activities throughout our courses are unlike any other method book or traditional way of learning.</p>

                <p class="heading-2">How is Encore different from standard method books?</p>

                <p class="info-bold">Encore SMART Teaching</p>
                <p class="info">Encore develops genuine musicianship skills as opposed to just reading and playing from the page. <span class="info-link" @click="changePage(0)">Read more about the Encore SMART Teaching here.</span></p>
			
                <p class="info-bold">World-class backing tracks &amp; audio playback app</p>
                <p class="info">The Encore backing tracks are unmatched by any other program. The high-level production qualities feature outstanding musical arrangements, professional musicians, exceptional singers, and state of the art mixing and mastering. They are not merely solo instrumental recordings, but complete ensemble arrangements for orchestra, jazz trio, rock band, folk group and numerous other combinations. Our exclusive Encore Music Wiz app enables teachers and students to play our high quality recordings at any tempo and in a range of keys. This facilitates faster learning and fosters creativity. <span class="info-link" @click="$router.push({ name: 'musicwiz'})">Learn more about our Encore Music Wiz app here.</span></p>

                <p class="info-bold">Rhythm &amp; musical engagement is at the forefront</p>
                <p class="info">
                    Encore learning always starts with musical experiences built on a strong rhythmic
                    foundation. Syncopation will often be an early and regular feature in the Encore
                    courses because it is intrinsic to so many styles. A number of other methods treat
                    rhythm as a secondary consideration and thus place more focus on pitch. These
                    methods tend to avoid syncopation because it is more difficult to teach if only a
                    traditional counting approach is used.
                </p>
                <p class="info">
                    With Encore, students learn and experience rhythm through singing, movement, body
                    percussion, gestures, speech patterns, lyrics, time names, percussion playing and
                    flash cards. They are always actively listening and responding to the music rather
                    than just reading notes from the page. Such an approach ensures that learning is
                    always occurring within a musical environment. Students are constantly involved in
                    the music-making process and experience a broad range of musical styles that include
                    Baroque, Classical, Rock, Blues, Latin, Contemporary and many more.    
                </p>

                <p class="info-bold">Whole pattern learning</p>

                <p class="info">
                    Whole pattern learning is intrinsic to the Encore system. Such an approach promotes
                    an ease of learning that is accurate and highly efficient. It is a means of learning
                    without error because all the musical elements are connected and related to each
                    other. Learning through whole patterns helps students internalise the fundamentals of
                    music. They are able to conceive music as a language with logical forms of
                    communication rather than a series of random notes. Musical patterns or structures
                    may be expressed visually (music score, keyboard patterns, finger patterns, chord
                    shapes), aurally (listening, singing) and physically (moving, feeling, gesturing,
                    playing).
                </p>
                
                <p class="info-bold">Motifs and harmonic structures</p>
                <p class="info">
                    Encore students become accustomed to hearing, learning and playing music in logical
                    units. A strong emphasis is placed on teaching musical ideas such as motifs, themes,
                    phrases and complete musical sections. This provides students with an experience
                    that is always musical and contextual. Students develop inherently musical qualities
                    because a genuine musical aesthetic is present throughout their learning.
                </p>
                <p class="info">
                    The Encore courses have a very strong harmonic foundation that mirrors the
                    importance of harmonic structures in Western music. Rather than simply playing
                    notes from the page, students get inside the music and learn the harmonic
                    progressions that underpin everything they play. The whole Encore system is closely
                    aligned with the fundamentals of Western harmony.
                </p>

                <p class="info-bold">Suitable for group and private tuition</p>
                <p class="info">All the Encore materials have been specifically developed for both group and private tuition. Teaching resources designed for private teaching only, lack the flexibility to work in a group context. Encore is eminently suited to both settings.</p>

                <p class="info-bold">Playing hands together</p>
                <p class="info"><span class="info-italic">Encore on Keys</span> incorporates hands together playing from the first lesson and treats both hands as equal partners. Most other methods focus on separate hand playing and miss the advantage of developing teamwork between the hands from day one.</p>



                <p class="info-bold">Kinder Beat<span class="reg">&reg;</span></p>
                <p class="info">
                    <span class="info-italic">Kinder Beat</span> is a structured and progressive curriculum. Each piece and each book
                    builds on the skills that have been learnt from the first and subsequent lessons. It
                    uses a layered approach that provides additional challenges and creative experiences
                    once the fundamentals have been achieved. Most other early childhood programs lack
                    the specific learning outcomes that are pivotal to the 
                    <span class="info-italic">Kinder Beat</span> program.</p>

                <p class="info-bold">Encore backing tracks often use lead instruments other than a piano or stringed instrument</p>
                <p class="info">This is such an important aspect of <span class="info-italic">Encore on Keys</span>. If the piano part on the backing track is played by a piano, then it is very difficult for students to play along. This is because the piano sounds cancel each other out and students may not know if they are in time or not. The same is true for <span class="info-italic">Encore on Strings</span> with regard to not using a stringed instrument as the lead voice. By using a different lead instrument, students can easily gauge the accuracy of their own playing, and make whatever adjustments may be necessary.</p>

                <br>

                <p class="info-bold">As a result of these differences, Encore students learn faster and play more musically because stylistic elements will have been internalised. This understanding and experience also lays the foundation for creative engagement such as improvisation and composition.</p>
            </div>

            <div class="content-wide" v-if="pageIndex == 2">
				<p class="heading handwriting left" style="margin-bottom: 0px">Encore &amp; Exams</p>

                <p class="info-bold">Encore provides the flexibility to include examinations as part of a student’s musical journey.</p>

                <p class="info">
                    Many pieces featured in the Encore books are listed on the AMEB,
                    ANZCA and SCSM syllabi. Pieces that are not on any of the
                    above syllabi may be used as extra lists or as own choice
                    selections whenever that option is available.
                </p>

                <p class="info">
                    Encore provides a complete curriculum that integrates every
                    aspect of teaching and learning music. It develops a wider range of
                    skills than those that are assessed by most examination boards.
                    A number of teachers use Encore as their sole curriculum while
                    others use it as their core curriculum. Either way, Encore offers the versatility to
                    include exams as part of a broad range of musical experiences.
                </p>
			
                <div class="smart-coloured-container">
					<p class="course-section-heading">Encore Grading</p>
                    <p class="course-info">The following list provides a guide on how the Encore courses correlate to examination grades. For more detail, please refer to the current syllabus as published by each examination board.</p>
					
                    <p class="course-facts-title">Encore on Keys - Junior Piano</p>
                    <ul>
						<li class="course-info"><span>Book 1</span> - No Grading</li>
                        <li class="course-info"><span>Book 2</span> - Beginner/Preparatory</li>
                        <li class="course-info"><span>Book 3</span> - Beginner/Preparatory</li>
                        <li class="course-info"><span>Book 4</span> - Preparatory/Preliminary</li>
					</ul>

                    <p class="course-facts-title">Encore on Keys - Primary Piano</p>
                    <ul>
						<li class="course-info"><span>Book 1</span> - Beginner/Preparatory</li>
                        <li class="course-info"><span>Book 2</span> - Preparatory/Preliminary</li>
					</ul>

                    <p class="course-facts-title">Encore on Keys - Accent Piano</p>
                    <ul>
						<li class="course-info"><span>Book 1</span> - Beginner/Preparatory</li>
                        <li class="course-info"><span>Book 2</span> - Preparatory/Preliminary</li>
					</ul>

                    <p class="course-facts-title">Encore on Keys - Achiever Piano</p>
                    <ul>
						<li class="course-info"><span>Book 1</span> - Preliminary</li>
                        <li class="course-info"><span>Book 2</span> - Grade One</li>
                        <li class="course-info"><span>Book 3</span> - Grade Two</li>
                        <li class="course-info"><span>Book 4</span> - Grades Three &amp; Four</li>
					</ul>

                    <p class="course-facts-title">Encore on Strings - Music Maestros</p>
                    <ul>
						<li class="course-info"><span>Book 1</span> - Preparatory</li>
                        <li class="course-info"><span>Book 2</span> - Preliminary/Grade One</li>
					</ul>
				</div>               
            </div>
		</div>
    </div>
</template>

<script>

export default {
    props: [
		'section'
	],
    data() {
        return {
            pageIndex: 0
        }
    },
    methods: {
        changePage(index) {
			this.pageIndex = index;
		}
    },
	mounted() {
		if (this.section) {
			this.changePage(this.section);
		}
	},
    watch: {
        section: function(val) {
            if (val == undefined) {
                val = 0;
            }
            
            this.changePage(val);
        }
    }
}
</script>